import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import "../assets/css/main.css";
import "../assets/css/util.css";
import Header from "./Header";
import eyeopen from "../assets/img/icon-outline-eye.svg"
import eyeclose from "../assets/img/visibilty_off.svg"
// import { useDispatch } from "react-redux";
// import { loginSuccess } from "../store/authSlice";

const LoginTemplate = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false)

  const handleLogin = (e) => {
    e.preventDefault();

    const getCustomerDetails = () => {
      setLoading(true);
      // console.log("triggered");
      fetch(
        `https://ghmarkets.net/test/ghmarketconnect/?ghw=login&svg=list&email=${username}&password=${password}`
      )
        .then((response) => response.json())
        .then((json) => {
          setLoading(false);

          if (json["results"] && json["results"].length > 0) {
            // Assuming you get user data from login response
            // const userData = { username: username };

            // // Dispatch login success action with user data
            // dispatch(loginSuccess({ user: userData }));

            setIsLoggedIn(true);
            setError("");
            // Store username and email in session storage
            localStorage.setItem("username", username);
            localStorage.setItem("firstname", json["results"][0].firstname);
            localStorage.setItem("customcode", json["results"][0].code);

            // Redirect to home page upon successful login
            navigate("/");
          } else {
            setError("Invalid username or password");
          }
        });
    };
    getCustomerDetails();
  };

  return (
    <>
      <Header />
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form
              className="login100-form validate-form p-l-55 p-r-55 p-t-178"
              onSubmit={handleLogin}
            >
              <span className="login100-form-title">Sign In</span>

              <div
                className="wrap-input100 validate-input m-b-16"
                data-validate="Please enter email"
              >
                <input
                  className="input100"
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Email"
                />
                <span className="focus-input100"></span>
              </div>

              <div
                className="wrap-input100 validate-input passwordinput"
                data-validate="Please enter password"
              >
                <input
                  className="input100"
                  type={isVisible ? 'text' : 'password'}
                  name="pass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                {isVisible ? <img src={eyeopen} alt="" className="eyeopen" onClick={() => { setIsVisible(!isVisible) }} /> : <img src={eyeclose} alt="" className="eyeopen" onClick={() => { setIsVisible(!isVisible) }} />}


                <span className="focus-input100"></span>
              </div>
              {error && (
                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              )}
              <div className="text-right p-t-13 p-b-23">
                <span className="txt1 m-r-2">Forgot</span>

                <NavLink to="forgotpass" className="txt2">
                  Password?
                </NavLink>
              </div>

              <div className="container-login100-form-btn">
                <button className="login100-form-btn">
                  {loading ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    "Sign in"
                  )}
                </button>
              </div>

              <div className="flex-col-c p-t-120 p-b-40">
                {/* <span className="txt1 p-b-9">Don’t have an account?</span>

                <NavLink to="#" className="txt3">
                  Sign up now
                </NavLink> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginTemplate;
