import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
// import Superdeals from "../components/Superdeals";
import "../assets/css/cart.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { NavLink, useNavigate } from "react-router-dom";

import { API_BASE_URL } from "../config/connect";
import { emptyCart } from "../store/cartSlice";

// import money from "../assets/img/payment/money.png";
import momo from "../assets/img/payment/momo.webp";
import vodacash from "../assets/img/payment/vodacash.png";
import atmoney from "../assets/img/payment/atmoney.png";
import cards from "../assets/img/payment/cards.png";
import CheckoutPaySummary from "../components/CheckoutPaySummary";

const CheckoutSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [result, setResult] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [payInfo, setPayInfo] = useState({});
  const [payLoading, setPayLoading] = useState(false);
  const cartProducts = useSelector((state) => state.cart);
  const cartItems = JSON.parse(localStorage.getItem("cart"));
  const [paymentlink, setPaylink] = useState("");
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);

  const getCartProducts =
    cartProducts?.products?.length > 0 ? cartProducts : cartItems;

  const [data, setData] = useState({
    fname: "",
    lname: "",
    phonenumber: "",
    email: "",
    address: "",
    confirmemail: "",
    region: "",
    cart: getCartProducts,
  });

  const handleInput = (event) => {
    const { name, value } = event.target;

    setData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const notify = (mode) =>
    mode !== "" && result["msg"] ? toast[mode](result["msg"]) : "";

  const handlePayment = async () => {
    setPayLoading(true);

    // navigate("/checkout/summary/");

    setData({
      fname: userInfo.firstname,
      lname: userInfo.lastname,
      phonenumber: userInfo.phonenumber,
      email: userInfo.email,
      address: userInfo.address,
      confirmemail: userInfo.email,
      region: userInfo.state,
      cart: getCartProducts,
    });

    const res = await fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=processpayment&svg=pay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fname: userInfo.firstname,
        lname: userInfo.lastname,
        phonenumber: userInfo.phonenumber,
        email: userInfo.email,
        address: userInfo.address,
        confirmemail: userInfo.email,
        region: userInfo.state,
        payoption: payInfo.paymentoption,
        payopotncontact: payInfo.paymentoptionnumber,
        cart: getCartProducts,
        tax: taxPercentage,
        taxamt: taxAmt,
        gtotal: (
          parseFloat(getCartProducts.productsAmt) + parseFloat(taxAmt)
        ).toFixed(2),
      }),
    });
    const response = await res.json();
    setResult(response);

    if (response) {
      let mode = result["error"] === "1" ? "success" : "error";
      notify(mode);

      // save client info to localstorage
      // localStorage.setItem("cdi", JSON.stringify(""));

      console.log("userInfo");
      console.log(response["error"]);
      // unset user input

      if (response["error"]) {
        console.log("dispatching");
        // clear cart localstorage
        dispatch(emptyCart());
      }
      // localStorage.setItem("cart", JSON.stringify({}));
    }

    console.log("responseing");
    console.log(response);
    //console.log(response.data.paylink);

    const paylink = response.data.paylink;
    if (paylink) {
      console.log(paylink);
      setPaylink(paylink);

      // if (paymentlink) {
      console.log("Redirecting to Payment...");
      const timeout = setTimeout(() => {
        setPayLoading(false);
        window.location.assign(paylink);
      }, 2000);
      return () => clearTimeout(timeout);
      // }
    }

    //console.log("opportunity...");
    //console.log(paymentlink);
  };

  const getCustomerInfo = async (data) => {
    console.log("logging here....");
    console.log(JSON.stringify(data));
    const res = await fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=processpayment&svg=getcustomerinfo`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const response = await res.json();
    return response;
  };

  const getTaxes = async () => {
    const res = await fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=processpayment&svg=gettaxes`);
    const response = await res.json();
    return response;
  };

  useEffect(() => {
    const cusemail = JSON.parse(localStorage.getItem(btoa("cdi")));
    const payid = JSON.parse(localStorage.getItem(btoa("pid")));
    const taxes = getTaxes();
    taxes.then((js) => {
      setTaxPercentage(js["data"]);
      setTaxAmt(
        ((taxPercentage * getCartProducts?.productsAmt) / 100).toFixed(2)
      );
    });

    console.log("taxes");
    console.log(cusemail);
    console.log(payid);

    if (payid) {
      const autores = getCustomerInfo({
        cdi: cusemail,
        po: payid["po"],
        pon: payid["pon"],
      });

      autores.then(
        (value) => {
          setUserInfo(value.data.cd);
          setPayInfo(value.data.pd);
        },
        (error) => {
          console.log(error);
        }
      );
    }

    // let mode = result["error"] == "1" ? "success" : "error";
    // notify(mode);

    // if (paymentlink) {
    //   console.log("Redirecting to Payment...");
    //   const timeout = setTimeout(() => {
    //     setPayLoading(false);
    //     window.location.assign(paymentlink);
    //   }, 2000);
    //   return () => clearTimeout(timeout);
    // }
  }, [
    result,
    paymentlink,
    taxAmt,
    getCartProducts,
    taxPercentage,
    userInfo,
    payInfo,
  ]);

  return (
    <>
      <Header />

      <section className="coupon-product-area pt-30 pb-60">
        <div className="container pb-30">
          <div className="row mb-1">
            <NavLink to="/">
              <span>
                <i className="fa fa-arrow-left"></i> Go back & continue shopping
              </span>
            </NavLink>
          </div>
          <div className="row">
            <div className="col-sm-9 mb-20">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  Customer Details
                  <span className="">
                    <NavLink to="/checkout/address">
                      Change Customer Details
                    </NavLink>
                  </span>
                </div>
                <div className="card-body">
               
                  {typeof userInfo !== 'undefined' && Object.keys(userInfo).length > 0 ? (
                    <>
                      <div className="fw-bold mb-2">
                        {userInfo.firstname} {userInfo.lastname}
                      </div>
                      <address>
                        {userInfo.address} | {userInfo.city} |{" "}
                        {userInfo.phonenumber}
                      </address>
                    </>
                  ) : (
                    <>
                      <div className="fw-bold mb-2">Loading...</div>
                      <address>Loading Data ...</address>
                    </>
                  )}
                </div>
              </div>

              {/* <div className="card mt-20">
                <div className="card-header d-flex justify-content-between">
                  Delivery Details
                  <span className="">
                    <NavLink to="/checkout/shipping">
                      Add Delivery Details
                    </NavLink>
                  </span>
                </div>
              </div> */}

              <div className="card mt-20">
                <div className="card-header d-flex justify-content-between">
                  Payment method
                  <span className="">
                    <NavLink to="/checkout/payment"> Change Payment Option</NavLink>
                  </span>
                </div>
                <div className="card-body">
                  {Object.keys(payInfo).length > 0 ? (
                    <div className="row mb-2">
                      <div className="d-flex flex-wrap gap-3 ">
                        <div className="card cursor-pointer">
                          <label className="m-0">
                            <span className="btn-plain btn-block click-if-alone d-flex gap-2 align-items-center cursor-pointer">
                              {/* <img
                                width="50"
                                src={payInfo?.paymentoption}
                                alt=""
                              /> */}
                              <span className="fs-12">
                                {payInfo?.paymentoption}
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row mb-2">
                      <div className="d-flex flex-wrap gap-3 ">
                        <div className="card cursor-pointer">
                          <span className="btn-plain btn-block click-if-alone d-flex gap-2 align-items-center cursor-pointer">
                            <span className="fs-12">Loading...</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <CheckoutPaySummary
              handlePayment={handlePayment}
              payLoading={payLoading}
              userInfo={userInfo}
              payInfo={payInfo}
            />
          </div>
        </div>
      </section>

      {/* <Superdeals /> */}

      <ToastContainer />
    </>
  );
};

export default CheckoutSummary;
