import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ProdImagesSkeleton from "./ProdImagesSkeleton";
import { useProductData } from './ProductsContextApi';
import useFetch from "../hooks/useFetch";
import { API_BASE_URL } from "../config/connect";

function Productsimages({productcode}) {
  // const { prodcode } = useParams();
// console.log(productcode)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  // const allproImages = useProductData().allproImage;
  // const proimage = allproImages?.filter(
  //   (image) => image.productcode === prodcode
  // );

  // const {proimage, loading, error} = useFetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=subimages&prcode=${productcode}`, {}, [productcode], null)
  // const proimage = proimage?.filter(
  //   (image) => image.productcode === prodcode
  // );

  const [proimage, setProimage] = useState([]);
  useEffect(() => {

    const getAllImages = () => {
      try {
        fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=subimages&prcode=${productcode}`)
          .then((response) => response.json())
          .then((json) => {
            setProimage(json['results']);
            // console.log("12345");
            // console.log(prodcode);
            // console.log(json['results']);
          });
      } catch (error) {
        console.error('Internet Connection Down', error);
        throw error;
      }
    };

    getAllImages();

  }, [productcode]);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const modalStyles = {
    content: {
      width: "50%", // Adjust the width as needed
      height: "50%", // Adjust the height as needed
      margin: "auto", // Center the modal horizontally
      overflow: "auto", // Enable scrolling if content exceeds the modal size
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the overlay background color and transparency
    },
  };

  return (
    <div>
      <div className="shop-details-nav-wrap">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {proimage?.length > 0 ? (
              proimage?.map((post, index) => (
                <button
                  key={index}
                  className="nav-link"
                  onClick={() => openModal(index)}
                  type="button"
                  role="tab"
                  aria-controls={`nav-item-${index + 1}`}
                >
                  <img
                    src={post.image}
                    alt={`img-${index}`}
                    width="100px"
                    height="100px"
                  />
                </button>
              ))
            ) : (
              <ProdImagesSkeleton />
            )}
          </div>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Slider Modal"
        ariaHideApp={false}
        style={modalStyles} // Apply the custom styles
      >
        <div>
          <button
            style={{ color: "white", backgroundColor: "blue", border: "none" }}
            onClick={closeModal}
          >
            X
          </button>
          <Gallery
            items={proimage?.map((item) => ({
              original: item.image,
              thumbnail: item.image,
              description: item.description, // You can customize this based on your proimage
            }))}
            startIndex={selectedImageIndex}
            renderItem={(item) => (
              <div>
                <img
                  src={item.original}
                  alt={item.description}
                  style={{ width: "50%", height: "auto" }}
                />
                {item.description && (
                  <span className="image-gallery-description">
                    {item.description}
                  </span>
                )}
              </div>
            )}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Productsimages;
