import React, { useContext, useState, useCallback, useMemo  } from "react";
import Pagination from "../components/Pagination";
import Products from "../components/Products";
import Header from "../components/Header";
import Trendingproducts from "../components/Trendingproducts";
import { useProductData } from "../components/ProductsContextApi";
import Filtering from "../components/Filtering";
import { NavLink } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { API_BASE_URL } from "../config/connect";
function Commodities() {
  const { data, loading, error } = useFetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=allcommodities`, {}, [], null)

  // const allprods = useProductData().allProductData;
  // const products = useMemo(() => allprods?.filter((product) => product.producttype == "2"), [allprods]);
  // const [loading, setLoading] = useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState(data);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  // Function to handle filtering
  const applyFilter = useCallback((searchText, minPrice, maxPrice) => {
    const filtered = data?.filter((product) => {
      const productName = product.name?.toLowerCase() || "";
      const productDescription = product.description?.toLowerCase() || "";
      const productModel = product.model?.toLowerCase() || "";
      const productCategoryName = product.categoryname?.toLowerCase() || "";
      const productSubCategoryName = product.subcategoryname?.toLowerCase() || "";
      const productBrandName = product.brandname?.toLowerCase() || "";

      const searchTextMatch =
        productName.includes(searchText.toLowerCase()) ||
        productDescription.includes(searchText.toLowerCase()) ||
        productModel.includes(searchText.toLowerCase()) ||
        productCategoryName.includes(searchText.toLowerCase()) ||
        productSubCategoryName.includes(searchText.toLowerCase()) ||
        productBrandName.includes(searchText.toLowerCase());

      const priceMatch =
        (!minPrice || product.price >= parseFloat(minPrice)) &&
        (!maxPrice || product.price <= parseFloat(maxPrice));

      return searchTextMatch && priceMatch;
    });

    setFilteredProducts(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  }, [data]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = useMemo(
    () => filteredProducts?.slice(indexOfFirstPost, indexOfLastPost),
    [filteredProducts, indexOfFirstPost, indexOfLastPost]
  );

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };

  const toggleView = (viewType) => {
    setIsGrid(viewType);
  };

  return (
    <div>
      <Header />

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  <Filtering
                    onFilter={applyFilter} // Pass the applyFilter function
                    data={data} // Pass the products data to the Filtering component
                  />
                  <Trendingproducts>Trending Products</Trendingproducts>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">Groceries and Agric Commodities</h5>
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">All Commodities</label>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <Products slicedProducts={currentPosts} loading={loading} view={isGrid} />
                </div>
                <div className="shop-bottom-wrap">
                  {filteredProducts?.length > 0 ? (
                    <div className="shop-bottom-top">
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > filteredProducts.length
                          ? filteredProducts.length
                          : indexOfLastPost}{" "}
                        of {filteredProducts.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={filteredProducts.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    <div className="shop-bottom-top">
                      <p>No products found matching your criteria.</p>
                    </div>
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                      </form>
                      <ul>
                        <li
                          className={isGrid && "active"}
                          onClick={() => {
                            toggleView(true);
                          }}
                        >
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li
                          className={isGrid || "active"}
                          onClick={() => {
                            toggleView(false);
                          }}
                        >
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Commodities;
