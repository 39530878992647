import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import "../assets/css/cart.css";
// import coupon_product01 from "../assets/img/product/coupon_product01.jpg";
// import Topbar from "../components/Topbar";
// import Topsearch from "../components/Topsearch";
import Header from "../components/Header";
import Superdeals from "../components/Superdeals";
import { NavLink, useNavigate } from "react-router-dom";
// import CartItem from "../components/CartItem";
import { API_BASE_URL } from "../config/connect";

import { remove, incrementInCart, decrementInCart } from "../store/cartSlice";

const Cart = () => {
  const navigate = useNavigate();
  const cartProducts = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [sellerBusName, setSellerBusName] = useState("");
  const cartItems = JSON.parse(localStorage.getItem("cart"));

  const getCartProducts =
    cartProducts.products.length > 0 ? cartProducts : cartItems;

  const showCheckoutBtn =
    getCartProducts?.products?.length < 1 ||
    getCartProducts?.products?.length == null
      ? "disabled"
      : "";

  const removeFromCart = (id) => {
    // dispatch a remove action
    dispatch(remove(id));
  };

  const incrementItemQty = (id) => {
    dispatch(incrementInCart(id));
  };

  const decrementItemQty = (id) => {
    dispatch(decrementInCart(id));
  };

  const getProductDetails = () => {
    fetch(
      `https://ghmarkets.net/test/ghmarketconnect/?ghw=productservices&svg=sellerdetails&sellercode=${getCartProducts?.products[0]?.sellercode}`
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json["results"][0]);
        if (json["results"] && json["results"].length > 0) {
          setSellerBusName(json["results"][0].businessname);
        }
      });
  };
  useEffect(() => {
    getProductDetails();
  }, []);

  const gotoPayment = () => {
    const cusemail = localStorage.getItem(btoa("cdi"));
    const payid = localStorage.getItem(btoa("pid"));

    cusemail == null && payid == null
      ? navigate("/checkout/address/")
      : navigate("/checkout/summary/");
  };

  return (
    <>
      <Header />

      <section className="coupon-product-area pt-30 pb-60">
        <div className="container">
          <div className="row">
            {/* Cart Section */}
            <div className="col-lg-9">
              <div className="coupon-product-item-wrap mb-30">
                <div
                  className="card-header"
                  style={{ background: "transparent", border: "none" }}
                >
                  <h3 className="title text-white">
                    Cart
                    {getCartProducts?.products?.length > 0 &&
                      `(${getCartProducts?.productsNumber})`}
                  </h3>
                </div>

                {
                  // console.log('getCartProducts')
                  // console.log(getCartProducts)
                }

                {getCartProducts?.products &&
                  getCartProducts?.products?.map((product) => (
                    // <CartItem
                    //   product={product}
                    // removeFromCart={removeFromCart}
                    // key={product.prodcode}
                    // />

                    <div
                      className="row align-items-center cart-item mt-2"
                      key={product.prodcode}
                    >
                      <div className="col-lg-9">
                        <div className="coupon-product-item">
                          <div className="coupon-product-thumb">
                            <img src={product.previewpicture || product.picture} alt="img" />
                          </div>
                          <div className="coupon-product-content mt-25">
                            <h3
                              className="title"
                              style={{ display: "inline-block" }}
                            >
                              {product.name}
                            </h3>{" "}
                            <span>({sellerBusName})</span> <br />
                            <p>
                              {Object.entries(
                                product.selectedVariations || {}
                              ).map(
                                ([key, value]) =>
                                  value && (
                                    <React.Fragment key={key}>
                                      <span>{key.slice(8)}:</span> {value}
                                      <br />
                                    </React.Fragment>
                                  )
                              )}
                            </p>
                            <h4 className="price">GHC{product.price}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 cr">
                        <div
                          className="btn-group cart-rm-item"
                          onClick={() => removeFromCart(product.prodcode)}
                        >
                          <i className="fas fa-trash"></i>
                        </div>
                        <div className="coupon-code text-end ">
                          <div className="adjust-qty">
                            {product.quantity <= 1 && (
                              <button
                                className="btn btn-sm px-2 cart-btn"
                                onClick={() =>
                                  decrementItemQty(product.prodcode)
                                }
                                disabled
                              >
                                <i className="fas fa-minus"> </i>
                              </button>
                            )}
                            {product.quantity > 1 && (
                              <button
                                className="btn btn-sm px-2 cart-btn"
                                onClick={() =>
                                  decrementItemQty(product.prodcode)
                                }
                              >
                                <i className="fas fa-minus"> </i>
                              </button>
                            )}
                            <div className="cart-item-qty pl-5 pr-5 fs-6">
                              {product.quantity}
                            </div>
                            <button
                              className="btn btn-sm px-2 cart-btn"
                              onClick={() => incrementItemQty(product.prodcode)}
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {(getCartProducts?.products?.length < 1 ||
                  getCartProducts?.products == null) && (
                  <div className="row align-items-center cart-item">
                    <div className="col-lg-9">
                      <div className="coupon-product-item">
                        <div className="coupon-product-content mt-25">
                          <h3 className="title">No Items in your cart</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="row align-items-center cart-item">
                  <div className="col-lg-9">
                    <div className="coupon-product-item">
                      <div className="coupon-product-thumb">
                        <img src={coupon_product01} alt="img" />
                      </div>
                      <div className="coupon-product-content mt-25">
                        <h3 className="title">
                          Cap Caps New Cotton Baseball Cap With Embroidered
                        </h3>
                        <p>
                          <span>Seller:</span> Isakaba Ventures |{" "}
                          <span>Size:</span> L
                        </p>
                        <h4 className="price">$39.35</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 cr">
                    <div className="btn-group cart-rm-item">
                      <i className="fas fa-trash"></i>
                    </div>
                    <div className="coupon-code text-end ">
                      <div className="adjust-qty">
                        <button className="btn btn-sm px-2 cart-btn">
                          <i className="fas fa-minus"></i>
                        </button>
                        <span className="cart-item-qty">1</span>
                        <button className="btn btn-sm px-2 cart-btn">
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="coupon-product-item-wrap mb-30 move-bg">
                <div
                  className="card-header"
                  style={{ background: "transparent", border: "none" }}
                >
                  <h3 className="title text-white">
                    Cart <span className="text-transparent">Summary</span>
                  </h3>
                </div>
                <div className="coupon-product-content mt-25">
                  <div className="cart-summary-item">
                    <div className="cart-summary-item-label">Subtotal</div>
                    <div className="cart-summary-item-content">
                    GH₵ {isNaN(parseFloat(getCartProducts?.productsAmt)) ? '0.00' : parseFloat(getCartProducts?.productsAmt).toFixed(2)}
                    </div>
                  </div>

                  <p className="mt-3">Delivery fees not included yet.</p>

                  {/* <NavLink to="/checkout/summary/"> */}
                  <button
                    className={`btn btn-block ${showCheckoutBtn}`}
                    style={{ backgroundColor: "#F6921E" }}
                    onClick={() => {
                      gotoPayment();
                    }}
                  >
                    {/* Checkout (GH₵{parseFloat(getCartProducts?.productsAmt).toFixed(2) || 0}) */}
                    Checkout (GH₵{isNaN(parseFloat(getCartProducts?.productsAmt)) ?'0.00' : parseFloat(getCartProducts?.productsAmt).toFixed(2)})
                  </button>
                  {/* </NavLink> */}
                </div>
              </div>
              <div className="coupon-product-item-wrap mb-30 no-bg">
                <div className="coupon-product-content">
                  <p className="pt-1">
                    <b>Returning sold items?</b>
                  </p>
                  <p><NavLink to='/return'>View return policy </NavLink></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Superdeals />
    </>
  );
};

export default Cart;
