import React, { useState, useEffect } from "react";
// import { API_BASE_URL } from "../config/connect";
import { useProductData } from "./ProductsContextApi";

function AdsSlids() {
  const AdsSlids = useProductData().AdsSlids
  const [visibleAds, setVisibleAds] = useState(AdsSlids.slice(0, 4));

	//   const [AdsSlids, setAdsservice] = useState([]);


//   const getAllAdsServices = () => {
//     fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=productadsservices&svg=productadsservices`)
//       .then((response) => response.json())
//       .then((json) => {
//         const uniqueAds = json["results"]
//           .filter(
//             (ad, index, self) =>
//               index === self.findIndex((a) => a.picture === ad.picture)
//           )
//           .slice(0, 12);
//         setAdsservice(uniqueAds);
//         setVisibleAds(uniqueAds.slice(0, 4));
//       })
//       .catch((error) => {
//         console.error("Error fetching ads:", error);
//       });
//   };

//   useEffect(() => {
//     getAllAdsServices();
//   }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleAds((prevVisibleAds) => {
        const startIndex =
          (AdsSlids.indexOf(prevVisibleAds[0]) + 4) % AdsSlids.length;

        const nextAds = AdsSlids.slice(startIndex, startIndex + 4);

        return nextAds.length === 4
          ? nextAds
          : [...nextAds, ...AdsSlids.slice(0, 4 - nextAds.length)];
      });
    }, 15000);

    return () => clearInterval(intervalId);
  }, [AdsSlids]);

  return (
    <div id="adCarousel" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="row">
            {visibleAds.map((ad, index) => (
              <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
                <div className="product-card">
                  <img
                    src={ad.picture}
                    alt={ad.title || `Ad ${index + 1}`}
                    className="img-fluid uniform-image"
                  />
                  <div className="product-info">
                    <h5 className="brand-name">{ad.name || "Unknown Brand"}</h5>
                    <p className="price">GH₵{ad.price || "GH₵"}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdsSlids;